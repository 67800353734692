<template>
  <div class="feedback-message">
    <b-alert :show="true" class="alert-heading">
      <div class="alert-container">
        <custom-icon name="feedback_message" width="100%" :m0="false" />
        <p>
          Psst! Add at least {{ photosNeeded }} more photos to increase your likelihood of selling by {{ photosUploadPercent }}.
        </p>
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "FeedbackMessage",
  components: {
    CustomIcon: () => import("./CustomIcon.vue"),
  },
  props: {
    photosNeeded: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    photosUploadPercent() {
      return this.photosNeeded < 6 ? '33%' : '98%'
    }
  },
};
</script>

<style lang="scss">
.feedback-message {
  .alert {
    background-color: #82d6a3;
    border: 1px solid #1f8252;
    width: 98%;
    &.alert-heading {
      margin: 10px auto;
    }
  }
  .alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-bottom: 0;
      margin-left: 10px;
      color: #333333;
    }
    img {
      width: 20px !important;
    }
  }
}
@media screen and(min-width: 900px) {
    .feedback-message {
    .alert {
      width: 94%;
    }
    .alert-container {
      p {
        font-size: 17px;
      }
    }
  }
}
</style>